.App {
    display: flex;
}

.container {
    display: inline;
     text-align: left;
/*app*/
    flex-direction: column;
    /*font-family: Rubik;*/
/*fill*/
    display: flex; 
    flex-direction: column;
    justify-content: center;
    flex: 1; 
    word-break: break-word !important;
    align-items: normal !important;
}

.fill {
}

.navbar {
    align-items: normal !important;
}


.sessionButton {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-size: 17px;
}


.form {
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 27px;
}

.output {
    padding-left: 10%;
    padding-right: 25%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.output h1 {
    font-size: 30px;
    color: #333333; 
}


.output p {
    font-size: 24px;
    font-weight: normal;
    color: #333333;
}

.padsir {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 1%;
    padding-bottom: 1%;

}
